@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000 !important;
}
circle{
  color: #2fcbe0!important;
}
.MuiStepIcon-text , .css-117w1su-MuiStepIcon-text {
  fill: black!important;
}
.css-8t49rw-MuiStepConnector-line {
  border-color: #2fcbe0!important;
  min-height: 15px!important;
}
/* Style the scrollbar */
body::-webkit-scrollbar {
  width: 7px; /* width of the scrollbar */
}

/* Track */
body::-webkit-scrollbar-track {
  background: #f1f1f1; /* color of the scrollbar track */
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #02444d71; /* color of the scrollbar thumb */
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #2fcbe0; /* color of the thumb on hover */
}
.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}
.nav-item .nav-link,
.nav-item .nav-link:hover {
  color: white;
}
.active {
  font-weight: bold !important;
  color: #2fcbe0 !important;
}
@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #181a27 !important;
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 0rem !important;
  }
}
.not-active{
  color: white!important;
  font-weight: normal!important;
}
.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 4px;
  width: 0;
  border-radius: 16px;
  background: rgb(4, 187, 187);
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

.Typewriter__wrapper {
  font-size: 2.2em !important;
  color: white !important;
  border-bottom: 2px solid #2fcbe0;
  font-weight: 600 !important;
}
.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #2fcbe0 !important;
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 500 !important;
  }
  .Typewriter__cursor {
    display: none !important;
  }
}

/* FadeInSection.css */
.fade-in-section {
  opacity: 0;
  transform: translateY(40px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.fade-in-section.visible {
  opacity: 1;
  transform: translateY(0);
}

@media (prefers-reduced-motion: reduce) {
  .fade-in-section {
      transition: none;
  }
}
